import React, { useEffect, useState } from 'react'
import { Cascader } from 'antd';
import { getCity } from '../apis/canteen-info/list'
export default function ZgCascader(props: any) {
  const [city, setCity] = useState<any[]>([])

  //渲染下拉框数据
  useEffect(() => {
    getCity({}).then((res) => {
      setCity(res.data)
    })
  }, [])

  function onChange(data: any) {
    console.log("查询地区组件", data)
    props.getInputValue(data)
  }
  return (
    <>

      <span style={{ marginLeft: '10px', marginRight: '20px' }}>
        <span>所属区域：</span>
        {/* displayRender={displayRender} */}
        <Cascader options={city} onChange={onChange} changeOnSelect
        />
      </span>
    </ >
  )
}