import React from 'react'
import { Input } from 'antd';
import IconFont from './icon-font';
const { Search } = Input;
const prefix = (
  <IconFont type="iconfangdajing" style={{ color: '#A6B6C6' }} />
);
export default function ZgSearch(props: any) {
  function onSearch(data: any) {
    // console.log(data)
    props.getInputValue(data)
  }
  return (
    <>
      <Search
        placeholder={props.placeholder}
        enterButton="搜索"
        prefix={prefix}
        onSearch={onSearch}
      />
    </ >
  )
}