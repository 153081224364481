import { Form, Input, Button, message } from "antd"
import { ChangePassword } from "../apis/login"

export default function UpdatePass(props: any) {



  // 保存
  const onFinish = async (values: any) => {
    let data = {
      password: values.password,
      oldpassword: values.old_password
    }
    if (data.password === data.oldpassword) {
      message.warning('新旧密码一致，不需要修改！');
      return false
    }
    ChangePassword(data).then(res => {
      if (res.status === 200) {
        message.success('密码修改成功', 1);
        props.onComClick()
      }
    }).catch(function (error) {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
  }

  // 关闭修改密码弹窗
  const onClose = () => {
    console.log('props: ', props);
    props.onstateShow()
  }
  return (
    <>
      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="旧密码"
            name="old_password"
            rules={[{ required: true, message: '请输入旧密码!' }]}
          >
            <Input.Password placeholder="请输入旧密码" />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="password"
            rules={[{ required: true, message: '请输入新密码!' }, { min: 6, max: 16, message: '长度在 6 到 20 个字符' }]}
          >
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="password1"
            rules={
              [
                ({ getFieldValue }) => ({
                  required: true,
                  validator(rule, value) {
                    if (value) {
                      if (getFieldValue('password') !== value) {
                        return Promise.reject('两次输入的密码不一致')
                      } else {
                        return Promise.resolve()
                      }
                    } else {
                      return Promise.reject('请再次输入新密码')
                    }

                  }
                })
              ]}
          >
            <Input.Password placeholder="请再次输入新密码" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button style={{ marginRight: '15px' }} onClick={onClose}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>

      </div>
    </>
  )
}