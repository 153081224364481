import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { Layout } from "antd";
import "./layout.less";
import ZgHeader from "../components/zg-header";
import ZgSide from "../components/zg-side";
import $storeLocal from "storejs";

const { Header, Sider, Content } = Layout;
export interface layoutState {
  collapsed: boolean | undefined;
  route: any;
}
export interface layoutProps {
  children: any;
  route: any;
}
class Index extends Component<layoutProps, layoutState> {
  constructor(props: layoutProps) {
    super(props);
    this.state = {
      collapsed: true,
      route: props.route,
    };
  }
  public toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  public render() {
    // 根据登录的监管、教育等类型动态创建主题样式文件
    let loginType = $storeLocal.get("customPCType"); //1：监管端   5：教育端
    let cssSheet: any = null;
    if (loginType === "5") {
      cssSheet = import("../jiaoyu-color.css");
    } else {
      cssSheet = import("../jianguan-color.css");
    }
    let style = document.createElement("style");
    style.innerHTML = cssSheet;
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(style);
    return (
      <Layout className="wrap">
        <Header>
          <ZgHeader></ZgHeader>
        </Header>
        <Layout>
          <Sider
            breakpoint="xxl"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.toggleCollapsed}
          >
            <ZgSide></ZgSide>
          </Sider>
          <Content>{renderRoutes(this.state.route.children)}</Content>
        </Layout>
      </Layout>
    );
  }
}
export default withRouter(Index as any);
