import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ZgRow from "./zg-row";
import $storeLocal from "storejs";
import IconFont from "./icon-font";
import { Menu, Modal, message } from 'antd';
import UpdatePass from "./update-pass";
const { SubMenu } = Menu;
export interface headerProps {
  history: any;
}
export interface headerState {
  title: any;
  name: any;
  showPass: boolean
}
class zgHeader extends Component<headerProps, headerState> {
  constructor(props: headerProps) {
    super(props);
    this.state = {
      title: "3C智慧监管平台",
      name: "管理员",
      showPass: false
    };
  }
  public logout(e: any) {
    // e.preventDefault();
    $storeLocal.remove("customPCToken");
    this.props.history.push("/login");
  }
  componentDidMount() {
    let userInfo = $storeLocal.get("UserBaseInfo");
    if (userInfo) {
      this.setState({
        title: userInfo.mechanism_name,
        name: userInfo.realname,
      });
    }
  }
  handleClick() {
    this.setState(() => ({
      showPass: true
    }))
  }

  comClick() {
    this.setState(() => ({
      showPass: false
    }))
    $storeLocal.remove("customPCToken");
    this.props.history.push("/login");
    message.info('请重新登录')
  }

  stateShow() {
    this.setState(() => ({
      showPass: false
    }))
  }
  public render() {
    return (
      <div className="header-wrap">
        <ZgRow align={"center"} justify={"space-between"}>
          <span className="title">{this.state.title}</span>
          <ZgRow className="log-out">
            {/* 
            <span
              onClick={this.logout.bind(this)}
              style={{ color: "#fff", fontSize: "24px", marginLeft: "10px" }}
            >
              <IconFont type="iconshezhi" />
            </span> */}
            <span>{this.state.name}</span>
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '60px', marginTop: '4px' }}
            >
              <SubMenu
                key="sub1"
                className="setting"
                title={
                  <span className="submenu-title-wrapper">
                    <IconFont type="iconshezhi" />
                  </span>
                }
              >
                <Menu.Item key="1" onClick={() => this.handleClick()}>修改密码</Menu.Item>
                <Menu.Item key="2" onClick={this.logout.bind(this)}>退出登录</Menu.Item>
              </SubMenu>
            </Menu>
          </ZgRow>
        </ZgRow>
        <Modal
          visible={this.state.showPass}
          title="修改密码"
          footer={null}
          width="500px"
          onCancel={() => this.stateShow()}
        >
          <UpdatePass onstateShow={() => this.stateShow()} onComClick={() => this.comClick()}></UpdatePass>
        </Modal>
      </div>
    );
  }
}

export default withRouter(zgHeader as any);
