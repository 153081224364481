import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Menu } from 'antd'
// import { CarOutlined } from '@ant-design/icons';
import IconFont from './icon-font'
import { getCanteenAll } from '../apis/canteen-info/list'
import $storeLocal from 'storejs'
const { SubMenu } = Menu
export interface headerProps {
  history: any
}
export interface headerState {
  pathName: string
  firstCanteen: string
  openKeys: Array<string>
}
class zgSide extends Component<headerProps, headerState> {
  constructor(props: headerProps) {
    super(props)
    this.state = {
      pathName: '',
      firstCanteen: '',
      openKeys: ['']
    }
  }

  get pathName() {
    console.log('pathName ===>', this.props.history.location.pathname)
    // this.setState({
    //   pathName: this.props.history.location.pathname
    // })
    let str =
      '/' +
      this.props.history.location.pathname.split('/')[1] +
      '/' +
      this.props.history.location.pathname.split('/')[2]
    console.log(str)
    return str
  }

  // 路由跳转
  go = (obj: any, path: any = null) => {
    // console.log("sider click ===>", obj)
    //onClik那里虽然看不到传值,但是默认会传过来四个参数,详见官网
    // console.log("sider router ===>", this.props)
    if (path) {
      obj.key = path
    }
    if (this.props.history.location.pathname === obj.key) {
      // 当前页面不重复跳转
      return
    }
    this.props.history.push(obj.key) //编程式导航
    // console.log(obj)
  }
  componentDidMount() {
    getCanteenAll({}).then(res => {
      this.setState({ firstCanteen: res.data[0].canteen_number })
      $storeLocal.set('customFirstCanteen', res.data[0].canteen_number)
    })
  }

  onOpenChange = (keys: any) => {
    this.setState({
      openKeys: [keys[1]]
    })
  }

  public render() {
    return (
      <>
        <div className="left-menu-wrap">
          <Menu
            defaultSelectedKeys={[this.pathName]}
            selectedKeys={[this.pathName]}
            // defaultOpenKeys={["2"]}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            theme="dark"
          >
            <Menu.Item
              key={
                $storeLocal.get('customPCGrade') === 'city'
                  ? '/board/indexCity'
                  : $storeLocal.get('customPCGrade') === 'district'
                    ? '/board/indexArea'
                    : '/analysis/data-analysis'
              }
              onClick={this.go}
              icon={<IconFont type="iconzonglan" style={{ color: '#fff' }} />}
            >
              平台总览
            </Menu.Item>
            <SubMenu key="2" icon={<IconFont type="iconcanyin" style={{ color: '#fff' }} />} title="餐饮管理">
              <Menu.Item key="/canteen/list" onClick={this.go}>
                餐饮单位列表
              </Menu.Item>
              <Menu.Item
                key={'/canteen/info'}
                onClick={e => {
                  this.go(e, '/canteen/info/index?number=' + this.state.firstCanteen)
                }}
              >
                餐饮信息查询
              </Menu.Item>
            </SubMenu>

            {/*  商户信息 */}
            <SubMenu key="3" icon={<IconFont type="iconalert" style={{ color: '#fff' }} />} title="商户管理">
              <Menu.Item key={'/merchant/messageIndex'} onClick={this.go}>
                商户信息表
              </Menu.Item>
              <Menu.Item key={'/merchant/enterIndex'} onClick={this.go}>
                入驻商户分析
              </Menu.Item>
              <Menu.Item key={"/merchant/orderIndex"} onClick={this.go}>
                商户订单分析
              </Menu.Item>
            </SubMenu>

            <Menu.Item
              key="/supplier/list"
              onClick={this.go}
              icon={<IconFont type="icongongyingshang" style={{ color: '#fff' }} />}
            >
              供应商管理
            </Menu.Item>
            <Menu.Item
              key="/smartVideo/list"
              onClick={this.go}
              icon={<IconFont type="iconshexiangtou" style={{ color: '#fff' }} />}
            >
              明厨亮灶
            </Menu.Item>
            <Menu.Item
              key="/equipment-iot/list"
              onClick={this.go}
              icon={<IconFont type="iconwulianwang" style={{ color: '#fff' }} />}
            >
              物联监测
            </Menu.Item>
            {/* <Menu.Item
              key="/car-manage"
              icon={
                <CarOutlined style={{ color: "#A4ABC9" }} />
              }
            >
              <a href="http://ykypt.cn:7000/808gps/" target="_blank" rel="noopener noreferrer">车辆监管</a>
            </Menu.Item> */}
            <SubMenu key="4" icon={<IconFont type="iconalert" style={{ color: '#fff' }} />} title="预警管理">
              <Menu.Item key="/correction/list" onClick={this.go}>
                视频预警
              </Menu.Item>
              <Menu.Item key="/lotEarly/list" onClick={this.go}>
                物联预警
              </Menu.Item>
              <Menu.Item key="/materials/material" onClick={this.go}>
                原料预警
              </Menu.Item>
              <Menu.Item key="/certificates/list" onClick={this.go}>
                健康证预警
              </Menu.Item>
              <Menu.Item key="/canteen_ertificate/list" onClick={this.go}>
                餐饮证件预警
              </Menu.Item>
            </SubMenu>
            <SubMenu key="5" icon={<IconFont type="iconshanshiyisheng" style={{ color: '#fff' }} />} title="膳食管理">
              <Menu.Item key={'/meal/dish'} onClick={this.go}>
                菜品管理
              </Menu.Item>
              <Menu.Item
                key={'/meal/recommend'}
                onClick={e => {
                  this.go(e, '/meal/recommend/list')
                }}
              >
                菜谱推荐
              </Menu.Item>
            </SubMenu>
            <SubMenu key="6" icon={<IconFont type="icondashuju" style={{ color: '#fff' }} />} title="大数据分析">
              <Menu.Item key="/analysis/risk-area" onClick={this.go}>
                风险指数
              </Menu.Item>
              <Menu.Item key="/analysis/intelligence-rectify" onClick={this.go}>
                视频预警
              </Menu.Item>
              <Menu.Item key="/analysis/comprehensive-warning" onClick={this.go}>
                综合预警
              </Menu.Item>

              {$storeLocal.get('customPCGrade') === 'city' ? (
                <Menu.Item key="/analysis/appraiseIndex" onClick={this.go}>
                  评估分析
                </Menu.Item>
              ) : (
                ''
              )}
              <Menu.Item key="/analysis/networkIndex" onClick={this.go}>
                物联预警分析
              </Menu.Item>
            </SubMenu>
            <SubMenu key="7" icon={<IconFont type="iconwendang" style={{ color: '#fff' }} />} title="监管巡查">
              <Menu.Item key="/supervision/list" onClick={this.go}>
                风险监管
              </Menu.Item>
              <Menu.Item key="/inspection/list" onClick={this.go}>
                日常巡检
              </Menu.Item>
              <Menu.Item key="/self-check/list" onClick={this.go}>
                自查自评
              </Menu.Item>
              <Menu.Item key="/supervise/list" onClick={this.go}>
                监管督办
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="/interactive/list"
              onClick={this.go}
              icon={<IconFont type="iconhudong" style={{ color: '#fff' }} />}
            >
              共治监管
            </Menu.Item>
            <Menu.Item
              key="/PotluckUser/list"
              onClick={this.go}
              icon={<IconFont type="iconjucanchifan" style={{ color: '#fff' }} />}
            >
              聚餐申报
            </Menu.Item>
            <Menu.Item
              key="/information/list"
              onClick={e => {
                this.go(e, '/information/list/index')
              }}
              icon={<IconFont type="iconiconzhengli_xinwen" style={{ color: '#fff' }} />}
            >
              新闻资讯
            </Menu.Item>
          </Menu>
        </div>
      </>
    )
  }
}

export default withRouter(zgSide as any)
